.service-detail {
   h2 {
      margin: 0;
      color: $c1;
   }

   h4 {
      font-size: 1.3rem;
      color: $c2;
   }

   border-bottom: 1px solid #f1f1f1;
   margin-bottom: 20px;
   padding-bottom: 20px;
}