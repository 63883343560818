body {
	color: $text;
}

.loading-view {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		font-size: 4rem;

		path {
			fill: $c2;
		}
	}
}

.coupon-code {
	font-family: monospace;
	font-size: 1.3rem;
	background: #f1f1f1;
	border-radius: 10px;
	padding: 10px;
}

.coupon-inline {
	font-family: monospace;
	font-size: 1rem;
	background: #f1f1f1;
	border-radius: 10px;
	padding: 5px;
	display: inline-block;
	line-height: 1;
	cursor: pointer;

	&:hover {
		background: #ccc;
	}
}