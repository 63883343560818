header.header-view {
	box-shadow: 0px 5px 5px 0px rgba(black, .02) !important;
	position: relative;
	z-index: 2;
}

.header-inset-view {
	display: flex;
	justify-content: flex-end;

	@media (max-width: 999px) {
			padding: 10px 20px;
			padding-right: 0;	
	}

	.search-view {
		flex: 1;
		border-right: 1px solid #e5e5e5;
		padding-right: 20px;
		display: flex;
		align-items: center;

		@media (max-width: 999px) {
			border-right: 0px;	
			padding-right: 0;	
		}

		.anticon {
			margin-right: 10px;
		}

		svg {
			font-size: 20px;
			path {
				fill: $c1;
			}
		}
		
		input {
			margin-left: 10px;
		}
	}

	.balance-info {
		border-right: 1px solid #e5e5e5;
		padding: 0px 10px;

		.balance-badge .ant-badge-count {
			background: $c1;
		}
	}

	.user-info {
		margin-left: 20px;
		color: $c1;
		display: flex;
		align-items: center;

		@media (max-width: 999px) {
				margin-left: 0px;
		}

		.user-name {
			font-weight: bold;
			font-size: .8rem;
			@media (max-width: 999px) {
				display: none;
			}
		}

		.user-avatar {
			margin-left: 10px;
			width: 30px;
			height: 30px;
			border: 2px solid rgba($c2, .3);
			display: flex;
			align-items: center;
			justify-content: center;
			-webkit-border-radius: 40px;
			-moz-border-radius: 40px;
			-ms-border-radius: 40px;
			-o-border-radius: 40px;
			border-radius: 40px;

			@media (max-width: 999px) {
				display: none;
			}

			svg path {
				fill: $c2;
			}
		}

		
	}
}

.header-view {
	
}