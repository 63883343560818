//
// SWAL
//

.swal2-title {
	color: $c1 !important;
}

.swal2-confirm {
	background: $c2 !important;
}

//
// Loading Page
//

.loading-page-view {
	height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg path {
		fill: $c2;
	}
}



.loading-inline {
	display: flex;
	align-items: center;
	justify-content: center;

	svg path {
		fill: $c2;
	}
}

//
// Headinhs
//

h1.ant-typography {
	color: $c2 !important;
}

//
// Page title
//

.page-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;

	@media (max-width: 999px) {
		flex-direction: column;
		align-items: start;
	}
}

//
// Autocomplete
//

.search-view {
	position: relative;
}

.search-autocomplete {
	position: absolute;
	left: 0;
	top: 100%;
	margin-top: -10px;
	right: 20px;
	background: white;
	-webkit-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.17);
	-moz-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.17);
	box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.17);
	max-height: 400px;
	overflow: auto;
	padding: 20px;
	line-height: 1;

	.search-autocomplete-item {
		border-bottom: 1px solid #e5e5e5;
		float: left;
		width: 100%;
		margin-bottom: 20px;

		> span {
			font-size: 0.8rem;
			opacity: 0.6;
			margin-bottom: 10px;
			width: 100%;
			display: block;
		}

		.search-inner-item {
			height: 38px;
			line-height: 38px;
			cursor: pointer;
			&:hover {
				background: #f9f9f9;
			}
		}

		&:last-child {
			border-bottom: 0;
			margin-bottom: 0;
		}
	}
}

.group-view {
	border: 1px solid #e5e5e5;
	height: 400px;

	> .ant-row {
		height: 100%;

		.ant-col {
			max-height: 100%;
			display: flex;
			flex-direction: column;
		}
	}
	.gutter-row {
		padding: 0px;

		h4 {
			padding: 10px 20px;
			margin-top: 10px;
		}

		.row-inset {
			height: 90%;
			display: flex;
			flex-direction: column;

			&-search {
				padding: 0px 20px;
			}
		}

		.user-list {
			flex: 1;
			margin-top: 20px;
			overflow: auto;
			padding: 20px;
			padding-top: 0px;

			&::-webkit-scrollbar {
				width: 5px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				background: #f1f1f1;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #888;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #555;
			}

			ul {
				margin: 0;
				padding: 0;

				li {
					list-style: none;
					border-bottom: 1px solid #e5e5e5;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 6px 0px;

					&:hover {
						background-color: rgba($c1, 0.03);
					}

					&:last-child {
						border-bottom: 0;
					}

					> span {
						font-size: 0.8rem;
						font-weight: bold;
						color: $c1;
					}

					button {
						color: $text;

						&:hover {
							color: $c1;
						}
					}
				}
			}
		}

		&:first-child {
			border-right: 1px solid #e5e5e5;
		}
	}
}

.social-contract {
	h3 {
		margin-bottom: 20px;
		font-weight: normal;
		font-size: 1.4rem;
	}
	.ant-card {
		.ant-card-body {
			display: none;
		}
		.ant-card-head {
			color: $c1;
		}
		&.active {
			.ant-card-head {
				background: rgba($c1, .05);
			}
			.ant-card-body {
				display: block;
			}
		}
	}
}



.ticket-conversation {
	height: 100%;
	display: flex;
	flex-direction: column;

	&-messages {
		flex: 1;
		overflow: auto;

		.empty, .loading {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
	}

	&-textarea {
		padding-top: 20px;
		border-top: 1px solid #e5e5e5;
	}

	.ant-comment {
		margin-bottom: 15px;
		

		.ant-comment-inner {
			background: #f9f9f9;
			border: 2px solid #f5f5f5;
			padding: 15px;
			border-radius: 3px !important;

			.ant-comment-content-author-name {
				div {
					display: flex;

					.badge {
						margin-left: 10px;
						padding: 1px 5px;
						vertical-align: middle;
						border-radius: 10px;
						position: relative;
						margin-top: -2px;

						&.partner, &.partner_admin {
							background: #fb822a;
							color: white;
						}

						&.user {
							background: #41d154;
							color: white;
						}

						&.admin {
							background: #f5465b;
							color: white;
						}
					}
				}
			}
		}

		&.me {
			.ant-comment-inner {
				background: rgb($c1, .05);
				border: 2px solid rgba($c1, .15);
				color: $c1;

				.ant-comment-content-author-name {
					div {
						color: $c1 !important;
						font-weight: 500;
					}
				}
			}
		}
	}
}

.rate-item {
	display: flex;
	gap: 2rem;

	.rate-view {
		.anticon-star {
			color: #fec839;
		}
		.outline {
			color: #e5e5e5;
		}
	}

	.rate-description {
		flex: 1;

		em {
			color: $text;
			font-style: normal;
			display: block;
			font-size: .8rem;
			margin-top: 10px;
		}
	}
}