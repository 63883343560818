//@import 'vars';

.login {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	.login-wrapper {
		width: 100%;
		max-width: 500px;
		box-shadow: 0px 0px 20px 5px rgba(black, .05);
		padding: 30px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
		border-radius: 4px;
		border-bottom: 2px solid $c1;

		.logo-wrapper {
			text-align: center;
			padding: 30px 0px;
		}

		.options {
			display: flex;
			justify-content: space-between;
			padding-bottom: 20px;
		}
	}


	.register {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-top: 1px solid #e5e5e5;
		padding-top: 30px;

		> span {
			color: $text;
			margin-bottom: 10px;
		}




	}

}