.main-wrapper {
	.ant-layout {
		.ant-layout-content {
			min-height: auto;
		}
	}

	height: 100vh;

	.sider {
		height: 100%;

		.ant-menu {
			//height: 100%;
		}

		.account {
			border-top: 1px solid #e5e5e5;
			padding:20px;
			margin-top: 20px;

			.ant-btn {
				width: 100%;
			}
		}
	}

	.page-wrapper {
		padding: 30px;


		@media (max-width: 999px) {
			padding: 20px;
		}
		@media (max-width: 699px) {
			padding: 10px;
		}
	}

	.site-layout-background {
		background: white;
		padding: 30px;
		box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.05);

		@media (max-width: 999px) {
			padding: 20px;
		}
		@media (max-width: 699px) {
			padding: 20px;
		}
	}
}
