.report-table {
	display: inline-block;
	width: 100%;
	background: #f9f9f9;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 999px) {
			padding: 10px;
		}

	.report-table-inset {
		background: white;
		float: left;
		padding: 50px;
		box-shadow: 0px 0px 10px 5px rgba(black, .05);

		@media (max-width: 999px) {
			padding: 10px;
		}

		table {
			max-width: 800px;
			border-collapse: collapse;

			td {
				border: 1px solid #e5e5e5;
				padding: 10px;
			}

			thead {
				th {
					width: 100%;
					background: #f1f1f1;
					padding: 15px;
				}
			}
		}
	}
}