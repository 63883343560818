.fc .fc-toolbar-title {
	color: $c1;
}



.fc-button-primary {
 	color: $text !important;
 	border: 1px solid #d9d9d9 !important;
 	background: white !important;

 	&:hover {
 		color: $c1 !important;
 		border-color: rgba($c1, .7) !important;
 	}
 }