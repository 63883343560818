@import 'vars';

.user-info-view {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #faf7fa;

	.user-info-wrapper {
		width: 100%;
		max-width: 600px;
		box-shadow: 0px 0px 20px 5px rgba(black, .05);
		padding: 30px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
		border-radius: 4px;
		border-bottom: 2px solid $c1;
		margin-top: 100px;
		color: $text;
		background: white;
		margin-bottom: 50px;

		h2 {
			color: $c1;
			font-size: 2rem;
		}

		h3 {
			color: $c2;
			font-size: 1.6rem;
		}
	}

}