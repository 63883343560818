.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background: rgba($c1, .1) !important;
}


.ant-table {
	@media (max-width: 999px) {
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}
}